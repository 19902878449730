@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

$mobile-gutter: 15px;

// Bootstrap
$font-family-base: 'Poppins', sans-serif;
$btn-border-radius: 0;
$input-border-radius: 0;
$grid-breakpoints: (
  xs: 0,
  sm: 576px, // iphone vertical
  md: 768px, // iphone horizontal
  lg: 992px, // ipad
  xl: 1200px, // 13" and 15"
  xxl: 1500px // 24" and more
);

// Colors
$look-up-color: #572839;
$topics-color: #4d2232;
$tools-color: #421b2a;
$color-blue: #115477;
$box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .2);
