.right-menu-open {
  $barHeight: 5px;
  $space: 11px;
  $width: 40px;
  $barColor: #572839;
  width: $width;
  cursor: pointer;
  height: $barHeight * 3 + $space * 2;
  position: absolute;
  margin-top: 44px;
  right: 100px;
  padding: 0;
  background-color: transparent;
  @include media-breakpoint-down(sm) {
    right: $mobile-gutter;
    $barHeight: 4px;
    $space: 8px;
    $width: 25px;
    width: $width;
    height: $barHeight * 3 + $space * 2;
    margin-top: 10px;
    position: fixed;
  }

  body.front & {
    @include media-breakpoint-down(sm) {
      margin-top: 27px;
      position: relative;
      right: 0;
    }
  }

  body.front &,
  body.search-data-item & {
    position: fixed;
    @include media-breakpoint-down(sm) {
      position: absolute;
    }
  }

  body.search-data-item & {
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      position: fixed;
    }
  }

  body.node-type-anatomical-structure & {
    right: 250px;
    margin-top: 18px;
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      right: $mobile-gutter;
      position: fixed;
    }
  }

  body.node-type-data-item & {
    right: 40px;
    margin-top: 36px;
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      right: $mobile-gutter;
      position: fixed;
    }
  }

  body.node-type-data-item &,
  body.search-data-item & {
    z-index: 102;
  }

  .menu-icon {
    display: inline-block;
    height: 100%;
    width: $width;
    position: relative;

    .line {
      position: absolute;
      display: block;
      height: $barHeight;
      width: $width;
      background-color: #fff;
      transform: translate3d(0, 0, 0);

      body.front & {
        background-color: $barColor;
      }

      &.top {
        top: $barHeight;
      }

      &.middle {
        top: $barHeight + $space;
      }

      &.bottom {
        top: $barHeight + 2 * $space;
      }
    }
  }
}

.right-menu-close {
  $barWidth: 32;
  $barHeight: 6;
  cursor: pointer;
  position: absolute;
  top: 27px;
  right: 22px;
  padding: 0;
  display: block;
  height: unquote(round(sqrt(($barWidth * $barWidth) / 2)) + 'px');
  width: unquote(round(sqrt(($barWidth * $barWidth) / 2)) + 'px');
  background-color: transparent;
  @include media-breakpoint-down(sm) {
    top: 30px;
    right: $mobile-gutter;
  }

  &::after,
  &::before {
    position: absolute;
    margin-top: unquote(round(-$barWidth * 1 / 15) + 'px');
    margin-left: unquote(round(-$barWidth * 2 / 15) + 'px');
    left: 0;
    top: 50%;
    content: '';
    height: unquote($barHeight + 'px');
    width: unquote($barWidth + 'px');
    background-color: #d87b63;
    border-radius: unquote(($barHeight / 2) + 'px');
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

nav#right-nav {
  @include media-breakpoint-down(sm) {
    position: relative;
    z-index: 102;
  }

  div#navigation {
    position: fixed;
    z-index: 101;
    top: 0;
    bottom: 0;
    right: 0;
    width: 360px;
    background-color: #c35e48;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    body.admin-menu & {
      top: 29px;
      @include media-breakpoint-down(sm) {
        top: 0;
      }
    }

    body.node-type-data-item &,
    body.search-data-item & {
      z-index: 103;
    }

    form#user-login-form {
      padding: 75px 35px 0;
      @include media-breakpoint-down(sm) {
        padding-left: $mobile-gutter;
        padding-right: $mobile-gutter;
      }

      .fieldset-wrapper {
        > .form-item {
          &:first-child {
            margin-top: 0;

            input {
              margin-top: 0;
            }
          }
        }

        .form-actions {
          margin-bottom: 0;

          input {
            margin: 0 !important;
            width: 100%;
            background-color: #87d0c8;
            color: #fff;
            box-shadow: $box-shadow;
          }
        }
      }

      .user-actions {
        margin-top: 0.5rem;
        display: inline-block;
        font-size: 0.9rem;
        color: #73382c;

        a {
          color: inherit;
        }
      }
    }

    ul#main-menu,
    ul#user-menu {
      padding: 60px 35px 0;
      font-size: 1.25rem;
      @include media-breakpoint-down(sm) {
        padding-left: $mobile-gutter;
        padding-right: $mobile-gutter;
      }

      li {
        padding: 0.6rem 0;

        a {
          color: #fff;
        }
      }
    }

    ul#main-menu {
      padding-top: 0;
      padding-bottom: 30px;

      li {
        a {
          color: #73382c;
        }
      }
    }
  }

  .lumc_review_lumc_review_change_state_menu {
    padding: 20px 35px 0;
    font-size: 1.25rem;
    @include media-breakpoint-down(sm) {
      padding-left: $mobile-gutter;
      padding-right: $mobile-gutter;
    }

    .block-content {
      a {
        color: #fff;
        text-decoration: none;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          display: inline-block;
          background-color: #fff;
          margin-right: 10px;
        }

        &.drupal-lumc-show-reviewed {}

        &.drupal-lumc-show-non-reviewed::before {
          background-color: lighten(#73382c, 5%);
        }
      }
    }
  }
}
