body.page-node-take {
  background: #fff!important;
  display: flex;

  h1.title {
    background-color: #125477;
    color: #fff;
    padding: 28px 15px;
    text-align: center;
    margin: 0;
    font-size: 30px;
    line-height: 1;
    z-index: 101;
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      height: auto;
      padding: 1rem;
      font-size: 17px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    a#logo {
      position: absolute;
      left: 100px;
      height: 21px;
      font-size: 0;
      @include media-breakpoint-down(sm) {
        display: block;
        width: 22px;
        height: 22px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTExLjk5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmhvbWU8L3RpdGxlPjxnIGlkPSJDYWxxdWVfMiIgZGF0YS1uYW1lPSJDYWxxdWUgMiI+PGcgaWQ9IkxheWVyXzEiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PGcgaWQ9IllxV2dkeC50aWYiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTI1MCwwaDEzYzExLjQ2LDMuNzcsMTkuNTQsMTIuMTQsMjcuNzQsMjAuMzVxMjguNDQsMjguNDQsNTYuOTEsNTYuODZjMS4xOSwxLjE5LDIuNDQsMi4zMyw0LjM3LDQuMTYsMC02Ljg4LDAtMTIuNTMsMC0xOC4xOEEzMS43NCwzMS43NCwwLDAsMSwzODMuMzMsMzJjMTAuNDktLjA3LDIxLDAsMzEuNDgsMEM0MzQuMzgsMzIsNDQ4LDQ1LjY4LDQ0OCw2NS4zNGMwLDM1LjgsMCw3MS42MS0uMDgsMTA3LjQyYTEwLjQ3LDEwLjQ3LDAsMCwwLDMuNDYsOC4yM2MxMy42NSwxMy40NCwyNy4xMywyNyw0MC43LDQwLjU2LDguMTIsOC4wOSwxNi4xOSwxNi4xOSwxOS45NCwyNy40NXYxNGMtLjY5LDEuNjItMS40NCwzLjIyLTIuMDgsNC44NkEzMS4yLDMxLjIsMCwwLDEsNDgxLDI4OGMtNi4xNi4wNi0xMi4zMywwLTE4LjUsMEg0NDh2Ni42OHEwLDkyLjI1LDAsMTg0LjQ5YzAsMTktMTMuNzksMzIuODEtMzIuNzEsMzIuODJxLTQ1LDAtOTAsMGMtMS42MiwwLTMuMjMtLjEzLTUuMjUtLjIyVjM1NS4wNWMwLTIxLjc2LTEzLjQtMzUuMDYtMzUuMjktMzUuMDdIMjI3LjI0Yy0yMS45LDAtMzUuMjUsMTMuMjktMzUuMjYsMzUuMDhWNTEySDk3LjcyQzc3LjQxLDUxMiw2NCw0OTguNTMsNjQsNDc4LjE2VjI4OGMtMTEuNDEsMC0yMi4yMS4wNi0zMywwQTMxLDMxLDAsMCwxLDEuOTMsMjY3LjRDMS4zNywyNjUuOS42NSwyNjQuNDYsMCwyNjNWMjQ5YzMuMzQtOS4yMyw5LjE5LTE2LjcxLDE2LjA2LTIzLjU3UTEyMC45MywxMjAuNzIsMjI1LjcxLDE1LjkzQzIzMi43Miw4LjkxLDI0MC4yLDIuNzEsMjUwLDBaIi8+PC9nPjwvZz48L2c+PC9zdmc+');
        background-repeat: no-repeat;
        left: $mobile-gutter;
      }

      img {
        height: inherit;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  nav#right-nav {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .field-name-field-slide-entity-reference {
    .field-label {
      display: none;
    }
  }

  .field-type-image {
    img {
      max-height: calc(100vh - 100px);
      width: auto;
    }
  }

  iframe {
    width: 100%;
    min-height: calc(100vh - 100px);
  }

  input#edit-back,
  input#edit-submit {
    position: fixed;
    top: 50%;
    z-index: 1;
    transform: translate(0, -50%);
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: 30px;
    font-size: 0;
    display: block;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    border: 0;
    padding: 0;
  }

  input#edit-submit {
    right: 5px;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA2NC45IDcxLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0LjkgNzEuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTAsNTUuNmMwLTEwLjksMC0yOC44LDAtMzkuN2wwLTMuNkMwLDEuMyw3LjctMy4xLDE3LjIsMi4zbDMuMSwxLjhDMjkuOCw5LjYsNDUuMiwxOC41LDU0LjcsMjRsMy4xLDEuOGM5LjUsNS41LDkuNSwxNC40LDAsMTkuOGwtMy4xLDEuOGMtOS41LDUuNS0yNC45LDE0LjQtMzQuNCwxOS44bC0zLjEsMS44QzcuNyw3NC41LDAsNzAuMSwwLDU5LjJMMCw1NS42eiIvPjwvZz48L3N2Zz4=');
    background-position: 58% 50%;
    @include media-breakpoint-down(sm) {
      bottom: 0;
      top: auto;
      transform: none;
      height: 50px;
      background-size: 35%;
      z-index: 6;
    }

    &[value="Finish"] {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxNzEgMTcxIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNzEgMTcxIiB4bWw6c3BhY2U9InByZXNlcnZlIj48Y2lyY2xlIGZpbGw9IiMxODU0NzYiIGN4PSI4NS41IiBjeT0iODUuNSIgcj0iODUuNSIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMTgiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgcG9pbnRzPSI0NS44LDg3LjggNzAuOCwxMTIuOCAxMjUuMiw1OC4yICIvPjwvc3ZnPg==');
      background-color: transparent;
      background-size: 65px;
      color: #115477;
      font-size: 1.25rem;
      padding-bottom: 100px;
      height: 80px;
      border-radius: 0;
      background-position: 50% 100%;
      @include media-breakpoint-down(sm) {
        background-size: 50%;
        font-size: 0;
        background-position: 50%;
        height: 50px;
        padding: 0;
      }

      &.active,
      &:hover {
        color: #fff;
        background-color: transparent;
      }
    }
  }

  input#edit-back {
    left: 5px;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA2NC45IDcxLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0LjkgNzEuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTY0LjksNTUuNmMwLTEwLjksMC0yOC44LDAtMzkuN3YtMy42YzAtMTAuOS03LjctMTUuNC0xNy4yLTkuOWwtMy4xLDEuOEMzNS4xLDkuNiwxOS43LDE4LjUsMTAuMiwyNGwtMy4xLDEuOGMtOS41LDUuNS05LjUsMTQuNCwwLDE5LjhsMy4xLDEuOGM5LjUsNS41LDI0LjksMTQuNCwzNC40LDE5LjhsMy4xLDEuOGM5LjUsNS41LDE3LjIsMSwxNy4yLTkuOVY1NS42eiIvPjwvZz48L3N2Zz4=');
    background-position: 42% 50%;
    @include media-breakpoint-down(sm) {
      bottom: 0;
      top: auto;
      transform: none;
      height: 50px;
      background-size: 35%;
      z-index: 6;
    }
  }

  div#quiz_progress {
    position: fixed;
    left: 15px;
    top: 175px;
    z-index: 11;
    color: #fff;
    margin-top: 1rem;
    width: 40px;
    text-align: center;
    font-style: normal;
    @include media-breakpoint-down(sm) {
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      width: auto;
      height: 50px;
      z-index: 5;
      background: linear-gradient(to bottom,#2c7e8f,#93c3ca 50%,#82131f);
      background-attachment: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      @include media-breakpoint-down(sm) {
        margin: 0 5px;
      }
    }
  }

  div#main {
    display: flex;
    padding-bottom: 0;

    .left-side,
    .right-side {
      width: 75px;
      position: relative;
      @include media-breakpoint-down(sm) {
        position: fixed;
        bottom: 0;
        height: 50px;
      }

      &.inactive {
        background-color: #5c5e60;
      }

      &.theme-gradient {
        cursor: pointer;
      }
    }

    .left-side {
      @include media-breakpoint-down(sm) {
        left: 0;
      }

      .top-part {
        padding: 25px 0 135px;
        background-color: #572839;
        border-bottom-right-radius: 75px;
        position: fixed;
        width: inherit;
        z-index: 10;
        cursor: default;

        a#logo {
          display: block;
          text-align: center;
        }
      }
    }

    .right-side {
      @include media-breakpoint-down(sm) {
        right: 0;
      }

      .finish {
        &.active,
        &:hover {
          background: #115477!important;
        }
      }
    }

    #content-area {
      flex: 1;
      padding: 30px 135px;
      background: #fff;
      @include media-breakpoint-down(sm) {
        padding: calc(49px + 15px) $mobile-gutter calc(50px + 15px);
      }
    }
  }
}
