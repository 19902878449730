div.tabs {
  ul.tabs {
    padding: 0;
    margin: 0;

    li {
      a {}
    }
  }
}
