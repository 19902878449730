body.front {
  #main {
    > .container-fluid {
      padding-left: 100px;
      @include media-breakpoint-down(sm) {
        padding-left: $mobile-gutter!important;
        padding-right: $mobile-gutter!important;
      }
    }
  }

  .tab-wrapper {
    margin-top: 36px;
  }

  ul.nav-tabs {
    border-bottom: 0;
    color: #fff;
    position: relative;
    box-shadow: $box-shadow;
    border-top-right-radius: 30px;

    li.nav-item {
      margin-bottom: 0;
      border-top-right-radius: 30px;

      &:nth-child(1) {
        background-color: $look-up-color;
        width: 33.33%;
        position: relative;
        z-index: 3;
      }

      &:nth-child(2) {
        background-color: $topics-color;
        position: absolute;
        z-index: 2;
        left: 0;
        right: 33.33%;
        padding-left: 33.33%;
      }

      &:nth-child(3) {
        background-color: $tools-color;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        padding-left: 66.66%;
      }

      a.nav-link {
        border: 0;
        color: inherit;
        border-top-left-radius: 0;
        border-top-right-radius: 30px;
        background-color: inherit;
        padding: 0.75em 1em;
        font-size: 1.125rem;
      }

      & + .nav-item {
        margin-left: 0;
      }
    }
  }

  .tab-content {
    color: #fff;
    position: relative;
    box-shadow: $box-shadow;

    .separator {
      position: absolute;
      top: 63px;
      bottom: 53px;
      width: 1px;
      background-color: #fff;
      left: 50%;
    }

    #topics .separator {
      margin-left: -1rem;
    }

    a {
      color: inherit;
      padding: 0.5em 1em;
      font-size: 1.25rem;
      @include media-breakpoint-down(lg) {
        padding: 0.5em 0;
      }

      span.text {
        line-height: 60px;
        margin-left: 1rem;
        @include media-breakpoint-down(lg) {
          line-height: 50px;
          font-size: 0.85em;
          margin-left: 0.5rem;
        }
      }

      span.img {
        display: inline-block;
        vertical-align: top;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-radius: 100%;
        background-color: #833e57;
        @include media-breakpoint-down(lg) {
          width: 50px;
          height: 50px;
          line-height: 50px;
        }
      }
    }

    > div {
      padding: 1rem;
      min-height: 352px;

      &#look-up {
        background-color: $look-up-color;
        padding: 6rem 1rem;

        img {
          max-height: 85px;
        }

        .img {
          width: 136px;
          height: 136px;
          display: flex;
          margin-left: 0 auto;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
        }

        .mb-05 {
          margin-bottom: 0.5rem;
        }

        a.active {
          .img {
            background-color: #421b2a;
          }
        }
      }

      &#topics {
        background-color: $topics-color;
        @include media-breakpoint-down(sm) {
          padding-left: 30px;
          padding-right: 30px;
        }

        img {
          max-height: 30px;
          max-width: 30px;
        }
      }

      &#tools {
        background-color: $tools-color;
        padding: 4rem 1rem;

        img {
          height: 45px;
          width: auto;
        }

        a.nav-link {
          @include media-breakpoint-down(sm) {
            min-height: 142px;
          }
        }
      }
    }
  }
}
