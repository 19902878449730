@function sqrt($r) {
  $x0: 1; // initial value
  $solution: false;
  @for $i from 1 through 10 {
     @if abs(2 * $x0) < 0,00000000000001{
      // Don't want to divide by a number smaller than this
      $solution: false;
    }
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0) !global;
    @if ( abs($x1 - $x0) / abs($x1)) < 0,0000001 {
      // 7 digit accuracy is desired
      $solution: true;
    }
    $x0: $x1;
  }
  @if $solution == true {
    // If $r is negative, then the output will be multiplied with <a href="http://en.wikipedia.org/wiki/Imaginary_number">i = √-1</a>
    // (√xy = √x√y) => √-$r = √-1√$r
    @if $r < 0 {
       @return $x1 #{i};
    }
    @else {
       @return $x1;
    }
  }
  @else {
     @return"No solution";
  }
}
/* ==========================================================================
   Browsers hacks - usage: @include firefox {...}
   ========================================================================== */
@mixin firefox {
   @-moz-document url-prefix(){
    @content;
  }
}
@mixin webkit {
   @media screen and (-webkit-min-device-pixel-ratio:0){
    @content;
  }
}
@mixin safari {
  html:lang(en) > body {
    @content;
  }
}
@mixin chrome {
  body:nth-of-type(1) {
    @content;
  }
}
/* ==========================================================================
   Placeholder - usage: @include placeholder(#f00, #0f0, .2s);
   ========================================================================== */
@mixin placeholder-focus($default: #000, $focus: transparent, $transition: .1s) {
  &::-webkit-input-placeholder {
    color: $default;
    @include transition($transition);
  }

  &:focus::-webkit-input-placeholder {
    color: $focus;
  }

  &:-moz-placeholder {
    color: $default;
    @include transition($transition);
  }

  &:focus:-moz-placeholder {
    color: $focus;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $default;
    @include transition($transition);
  }

  &:focus::-moz-placeholder {
    color: $focus;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $default;
    @include transition($transition);
  }

  &:focus:-ms-input-placeholder {
    color: $focus;
  }
}
@mixin placeholder($default: #000, $hover: transparent, $transition: .1s) {
  &::-webkit-input-placeholder {
    color: $default;
    @include transition($transition);
  }

  &:focus::-webkit-input-placeholder,
  &:hover::-webkit-input-placeholder {
    color: $hover;
  }

  &:-moz-placeholder {
    color: $default;
    @include transition($transition);
  }

  &:focus:-moz-placeholder,
  &:hover:-moz-placeholder {
    color: $hover;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $default;
    @include transition($transition);
  }

  &:focus::-moz-placeholder,
  &:hover::-moz-placeholder {
    color: $hover;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $default;
    @include transition($transition);
  }

  &:focus:-ms-input-placeholder,
  &:hover:-ms-input-placeholder {
    color: $hover;
  }
}

.spinner {
  animation: rotator 1.4s linear infinite;
  @-webkit-keyframes rotator {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(270deg);
    }
  }
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(270deg);
    }
  }

  .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  }
  @-webkit-keyframes colors {
    0% {
      stroke: #2c7e8f;
    }

    50% {
      stroke: #82131f;
    }

    100% {
      stroke: #2c7e8f;
    }
  }
  @keyframes colors {
    0% {
      stroke: #2c7e8f;
    }

    50% {
      stroke: #82131f;
    }

    100% {
      stroke: #2c7e8f;
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }

    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }

    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
}
