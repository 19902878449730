#cboxOverlay,
#cboxWrapper,
#colorbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxBottomLeft,
#cboxMiddleLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingGraphic,
#cboxLoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxClose,
#cboxNext,
#cboxPrevious,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#cboxContent,
#cboxLoadedContent,
#colorbox {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

#cboxOverlay {
  background-color: #000;
}

#cboxNext,
#cboxPrevious {
  position: absolute;
  top: 50%;
  width: 16px;
  height: 16px;
  background: transparent;
  border: 0;
  font-size: 0;
  color: #fff;
  display: block;
  padding: 0;
  margin-top: -18px;

  &::before {
    position: absolute;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    top: 40%;
  }
}

#cboxPrevious {
  left: 15px;

  &::before {
    transform: rotate(-135deg);
    left: 0;
  }
}

#cboxNext {
  right: 15px;

  &::before {
    transform: rotate(45deg);
    right: 0;
  }
}

#cboxClose {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0);
  border: 0;
  font-size: 0;
  color: #fff;
  display: block;
  padding: 0;

  &::after,
  &::before {
    content: '';
    display: block;
    background-color: #fff;
    display: block;
    position: absolute;
    height: 3px;
    right: 0;
    left: 0;
    margin: 5px;
  }

  &::before {
    transform: rotate(45deg);
    top: 15px;
  }

  &::after {
    transform: rotate(-45deg);
    top: 15px;
  }
}

#cboxTitle {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  color: #FFF;
  font-size: 12px;
  line-height: 2em;
  padding: 0 10px;

  div {
    margin: 3px 8px;
  }
}

div#cboxCurrent {
  display: none!important;
}
