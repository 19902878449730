html {
  height: 100%;

  body {
    overflow: auto;

    div#page {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      min-width: 100%;

      div#main {
        flex: 1 0 auto;

        > .container-fluid {
          padding-left: 100px;
          @include media-breakpoint-down(md) {
            padding-left: $mobile-gutter;
          }
        }

        #content {
          #content-area {
            flex-flow: row wrap;

            #content-header {
              flex: 1 100%;
              margin-top: 1rem;
              margin-bottom: 1rem;

              &.container-fluid {
                padding-left: 100px;
                @include media-breakpoint-down(md) {
                  padding-left: $mobile-gutter;
                }
              }
            }
          }
        }
      }
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.animate-fade {
  &.ng-hide {
    opacity: 0;
  }

  &.ng-hide-add,
  &.ng-hide-remove {
    transition: all linear 0.15s;
  }
}

[ng-click] {
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

ul.list-unstyled {
  li {
    margin: 0;
  }
}
@include media-breakpoint-down(sm) {
  div#admin-menu {
    display: none;
  }

  html body.admin-menu {
    margin-top: 0 !important;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}
