ul.krumo-first,
ul.krumo-node {
  margin: 0 !important;
}

ul.krumo-node ul {
  margin-left: 20px !important;
}

.nav-stacked .nav-item {
  margin: 0;
}

.contextual-links-region-active {
  outline: #999 dashed 1px;
}

.filter-wrapper {
  display: none;
}

html.js fieldset.collapsed {
  height: auto;
}

form .field-multiple-table th.field-label {
  padding: 0.75rem;

  label {
    display: block;
    margin-bottom: 0;
  }
}

a.tabledrag-handle .handle {
  height: 28px;
  width: 26px;
  margin: 0;
}

.draggable a.tabledrag-handle {
  margin-left: 0;
  margin-right: -.5em;
}

a.tabledrag-handle-hover .handle {
  background-position: 6px 9px;
}

fieldset.collapsible {
  legend {
    font-size: 1rem;
  }
}

table.package {
  margin: 1em 0 0;
}

#module-filter-submit .form-actions {
  margin: 0;
}

#module-filter-submit input.form-submit {
  margin: 0 !important;
  max-width: 100%;
  white-space: normal;
}

.resizable-textarea .grippie {
  display: none;
}

td.checkbox,
th.checkbox {
  margin: 0;
  display: table-cell;
  vertical-align: middle;

  input[type="checkbox"] {
    position: static;
    margin: 0;
  }
}
