// a,
// abbr,
// acronym,
// address,
// applet,
// article,
// aside,
// audio,
// b,
// big,
// blockquote,
// body,
// canvas,
// caption,
// center,
// cite,
// code,
// dd,
// del,
// details,
// dfn,
// div,
// dl,
// dt,
// em,
// embed,
// fieldset,
// figcaption,
// figure,
// footer,
// form,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// header,
// hgroup,
// html,
// i,
// iframe,
// img,
// ins,
// kbd,
// label,
// legend,
// li,
// mark,
// menu,
// nav,
// object,
// ol,
// output,
// p,
// pre,
// q,
// ruby,
// s,
// samp,
// section,
// small,
// span,
// strike,
// strong,
// sub,
// summary,
// sup,
// table,
// tbody,
// td,
// tfoot,
// th,
// thead,
// time,
// tr,
// tt,
// u,
// ul,
// var,
// video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   font: inherit;
//   vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article,
// aside,
// details,
// figcaption,
// figure,
// footer,
// header,
// hgroup,
// menu,
// nav,
// section {
//   display: block;
// }
//
// body {
//   line-height: 1;
// }
//
// ol,
// ul {
//   list-style: none;
// }
//
// blockquote,
// q {
//   quotes: none;
// }
//
// blockquote:after,
// blockquote:before,
// q:after,
// q:before {
//   content: '';
//   content: none;
// }
//
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }
//
// *,
// *:focus,
// *:hover {
//   outline: 0;
// }
//
// button,
// input:not([type=radio]):not([type=checkbox]),
// select,
// textarea {
//   appearance: none;
// }
//
// input::-webkit-calendar-picker-indicator {
//   display: none;
// }
//
// input[type="date"]::-webkit-input-placeholder {
//   visibility: hidden !important;
// }
//
// input[type="date"]::-webkit-calendar-picker-indicator,
// input[type="date"]::-webkit-inner-spin-button {
//   display: none;
//   -webkit-appearance: none;
// }
//
// select::-ms-expand {
//   display: none;
// }

ul.views-rows,
ul.class-items,
ul.course-items {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
