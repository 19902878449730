body.page-do-quiz-generator {
  background: #f6f0f0 !important;

  .anatomical-wrapper {
    padding: 15px 25px;
    margin: 0;
    background-color: #fff;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);

    th.field-label {
      padding: 0 !important;
    }

    input#edit-filter-container-field-anatomical-structures-und-add-more {
      margin: 0 !important;
    }

    * {
      border: 0;
    }
  }

  form#lumc-quiz-generator-creation-form {
    padding-top: 15px;

    > div {
      display: flex;
      flex-flow: row wrap;
      padding-top: 65px;
      @include media-breakpoint-down(sm) {
        padding-top: 0;
      }

      .global-form-wrapper {
        flex: 0 1 360px;
        max-width: 360px;
        padding-left: 100px;
        @include media-breakpoint-down(sm) {
          padding-left: $mobile-gutter;
        }

        .tabledrag-toggle-weight-wrapper {
          display: none;
        }

        tr.even,
        tr.odd {
          background-color: rgba(0, 0, 0, 0);
        }

        input.field-add-more-submit {
          width: 100%;
          margin-right: 0;
        }

        .form-item-filter-container-language,
        .form-wrapper > .form-item {
          > label {
            display: block;
            font-size: 14px;
            font-weight: 400;
            padding: 15px 25px;
            margin: 0;
            background-color: #fff;
            box-shadow: $box-shadow;
            position: relative;
            z-index: 2;
            background-image: url('../images/picto-caret.png');
            background-repeat: no-repeat;
            background-position: right 15px center;
            cursor: pointer;

            &.open {
              & + .form-checkboxes,
              & + .select-wrapper {
                display: block;
              }
            }
          }
        }

        .form-item-filter-container-language .select-wrapper,
        .form-wrapper .form-checkboxes,
        .form-wrapper .select-wrapper {
          padding: 15px 25px;
          background-color: #f8f5f5;
          overflow: auto;
          position: relative;
          z-index: 1;
          box-shadow: $box-shadow;
          display: none;
        }

        input[type="checkbox"],
        input[type="radio"] {
          $base-color: #27ae60;

          &:checked,
          &:not(:checked) {
            display: none;

            & + label {
              position: relative;
              padding-left: 25px;
              cursor: pointer;
              margin-right: 0;
              margin-bottom: 0;
              display: inline-block;

              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                width: 13px;
                height: 13px;
                border: 0;
                border: 1px solid #d9d9d9;
                background-color: #fff;
              }

              &::after {
                content: '';
                position: absolute;
                width: 13px;
                height: 13px;
                top: 5px;
                left: 1px;
                transition: opacity 0.2s, transform 0.2s;
              }
            }
          }

          &:not(:checked) + label::after {
            opacity: 0;
            transform: scale(0);
            background-color: transparent;
          }

          &:checked + label::after {
            opacity: 1;
            transform: scale(1);
            background-color: #87d0c8;
          }
        }
      }

      .submit-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        margin-left: 60px;
        @include media-breakpoint-down(sm) {
          margin-left: $mobile-gutter;
        }
      }
    }
  }
}
