body.page-learning-paths {
  .items {
    article.node-quiz {
      background-color: #fff;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
      min-height: 160px;
      margin-right: 480px;
      @include media-breakpoint-down(xl) {
        margin-right: 0;
      }

      > a {
        color: inherit;
        display: block;
        padding: 0.8rem 2.5rem 0.8rem 0.8rem;

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }

      .review {
        position: absolute;
        background-image: url('../images/not-reviewed.svg');
        width: 30px;
        height: 30px;
        right: 0;
        top: 0;

        &.node-reviewed {
          background-image: url('../images/reviewed.svg');
        }
      }

      h2 {
        font-size: 1.5rem;
      }

      .image-wrapper {
        min-width: 134px;
        text-align: center;

        img {
          max-height: calc(160px - 1.6rem);
          width: auto;
        }
      }
    }
  }

  #quiz-container {
    position: fixed;
    top: 116px;
    bottom: 0;
    right: 0;
    width: 475px;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 101;
    color: #fff;
    @include media-breakpoint-down(sm) {
      top: 49px!important;
    }

    a {
      color: inherit;
    }

    .dataContent {
      font-size: 0.9em;
      overflow: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(sm) {
        margin-top: 45px;
      }

      .nodeInfo {
        &.info div + div.title {
          margin-top: 0.5rem;
        }

        .left-col,
        .right-col {
          flex: 1;
        }

        .left-col {
          margin-right: 10px;
        }

        .right-col {
          margin-left: 10px;
        }

        .comment-back {
          display: block;
          height: 23px;
          width: 23px;
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI0Ny42cHgiIGhlaWdodD0iNDQuM3B4IiB2aWV3Qm94PSIwIDAgNDcuNiA0NC4zIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0Ny42IDQ0LjMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik00Ny42LDIzLjVjLTAuMSwwLjctMC4yLDEuNC0wLjMsMi4xYy0wLjUsMy4zLTEuNyw2LjItMy41LDljLTEuNSwyLjItMy40LDQuMS01LjYsNS43Yy0yLDEuNC00LjEsMi41LTYuNSwzLjFjLTIuMSwwLjYtNC4zLDEtNi41LDAuOWMtMS44LDAtMy41LTEuNS0zLjYtMy40Yy0wLjItMiwxLjItMy44LDMtNC4xYzAuNi0wLjEsMS4zLTAuMSwxLjktMC4yYzIuOS0wLjIsNS41LTEuMiw3LjctM2MxLjYtMS4zLDIuOS0yLjgsMy45LTQuNmMwLjgtMS41LDEuMy0zLjIsMS42LTQuOWMwLjMtMi41LDAtNC44LTAuOS03LjJjLTAuOS0yLjQtMi40LTQuNC00LjMtNS45Yy0yLjEtMS43LTQuNC0yLjctNy0zLjFjLTIuOS0wLjQtNS43LDAtOC40LDEuM2MtMi4xLDEtMy44LDIuNC01LjMsNC4zYy0wLjMsMC4zLTAuNSwwLjctMC43LDEuMUMxMy4xLDE0LjcsMTMsMTUsMTMsMTUuMmMwLDAuNCwwLjIsMC43LDAuNywwLjdjMC40LDAsMC44LDAsMS4xLDBjMi41LDAsNC4xLDIuNCwzLjIsNC43Yy0wLjUsMS4yLTEuNSwxLjktMi44LDJjLTEuMSwwLjEtMi4yLDAtMy4zLDBjLTIuOCwwLTUuNSwwLTguMywwYy0yLDAtMy4xLTAuOS0zLjYtMi44YzAsMCwwLTAuMS0wLjEtMC4xYzAtNC4yLDAtOC4zLDAtMTIuNUMwLDcuMiwwLjEsNy4xLDAuMSw3YzAuMy0xLjMsMS4xLTIuMiwyLjQtMi41QzMuOCw0LjEsNS4yLDQuNiw2LDUuNkM2LjUsNi4zLDYuNyw3LDYuNyw3LjljMCwwLjQsMCwwLjcsMCwxLjFjMCwwLjIsMC4xLDAuMywwLjIsMC41YzAuMi0wLjEsMC4zLTAuMSwwLjUtMC4yQzcuNiw5LDcuOCw4LjgsNy45LDguNmMxLTEuMywyLjEtMi41LDMuNC0zLjVjMS44LTEuNSwzLjgtMi43LDYtMy41YzIuMi0wLjksNC41LTEuNCw2LjgtMS41YzQuMy0wLjIsOC4zLDAuNywxMi4xLDIuOEMzOS40LDQuNiw0Miw3LDQ0LDEwYzEuNywyLjYsMi45LDUuNSwzLjQsOC43YzAuMSwwLjcsMC4yLDEuNCwwLjMsMi4xQzQ3LjYsMjEuNyw0Ny42LDIyLjYsNDcuNiwyMy41eiIvPjwvZz48L3N2Zz4=');
          background-position: 60% 50%;
          background-repeat: no-repeat;
          background-size: 15px;
          position: absolute;
          right: 0;
          top: 24px;
          text-decoration: none;
        }

        .title-value {
          display: flex;
        }

        .title {
          flex: 1;
          font-weight: 700;
          font-size: 1.2em;
        }
      }

      .upperpart {
        padding: 20px 35px 0;
        @include media-breakpoint-down(sm) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .lowerpart {
        padding: 0 35px 20px;
        overflow: hidden;
        flex: 1 0 auto;
        @include media-breakpoint-down(sm) {
          padding-left: 15px;
          padding-right: 15px;
        }

        &.mCustomScrollbar {
          padding-right: 0;
          padding-bottom: 0;

          .mCSB_scrollTools {
            width: 10px;
          }

          .mCSB_inside > .mCSB_container {
            margin-right: 35px;
          }

          .mCSB_scrollTools .mCSB_draggerContainer {
            background-color: #7f7b7a;
          }

          .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
            width: 8px;
          }

          .mCSB_scrollTools .mCSB_draggerRail {
            display: none;
          }
        }
      }

      .institutionPart {
        text-align: right;
        margin-left: auto;

        img {
          height: 35px;
          width: auto;
          max-width: 100%;
        }
      }

      .educationalLevel {
        span {
          color: #777;

          &.active {
            color: #fff;
          }
        }
      }

      .separator {
        height: 1px;
        background-color: #999;
        margin: 30px 0;
      }

      .bottom,
      .top {
        display: flex;
      }

      .imageDirection {
        img {
          height: 4.5em;
          width: auto;
          max-width: none;
        }
      }

      .license {
        img {
          height: 2.6em;
          width: auto;
          max-width: none;
        }
      }

      .itemTitle {
        line-height: 1.2;
      }

      .author {
        line-height: 1.2;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      .credits,
      .institution {
        font-size: 10px;
      }

      .serif {
        font-family: serif;
      }

      .uppercase {
        text-transform: uppercase;
      }

      .italic {
        font-style: italic;
      }

      .nodeInfo {
        font-size: 12px;
        padding-bottom: 30px;
      }

      .image {
        height: 272px;
        background-position: center;
        background-size: auto 70%;
        background-repeat: no-repeat;
        position: relative;
        background-color: #fff;
        color: #999;
        padding: 15px;
        margin-top: 0.5rem;

        &.thumbnail {
          background-size: cover;
        }

        .image-link {
          position: absolute;
          display: block;
          height: calc(100% - 52px);
          left: 0;
          right: 0;
          top: 0;
        }

        .node-links {
          position: absolute;
          bottom: 10px;
          left: 10px;

          .infoTrigger {
            background-color: #741164;
            height: 25px;
            width: 25px;
            display: inline-block;
            vertical-align: top;
            border-radius: 100%;
            cursor: pointer;
            background-image: url('../images/picto-info.png');
            background-position: center;
            background-repeat: no-repeat;

            &.active {
              box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.7);
            }
          }

          .manage-item {
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            height: 25px;
            line-height: 25px;
            border-radius: 12px;
            padding: 0 15px;
            font-size: 0.7rem;
            text-decoration: none;
            display: inline-block;
            vertical-align: top;
            text-transform: uppercase;
          }

          .review {
            background-color: #1db759;
            height: 25px;
            line-height: 25px;
            border-radius: 12px;
            display: inline-block;
            vertical-align: top;
            color: #fff;
            padding: 0 15px;
            font-size: 0.7rem;
            text-decoration: none;

            &.not-reviewed {
              background-color: rgba(0, 0, 0, 0.5);
            }

            &.active {
              box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.7);
            }
          }
        }

        .search {
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 8px;
          position: absolute;
          bottom: 10px;
          background-size: 18px;
          background-repeat: no-repeat;
          background-position: center;
          color: #fff;
          text-transform: uppercase;
          line-height: 32px;
          padding: 0 12px;
          font-size: 10px;

          &:hover {
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.75);
          }
        }

        .manage-item {
          left: 10px;
        }

        .search {
          right: 10px;
        }
      }
    }

    .spinner-container {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -32.5px;
      margin-left: -32.5px;

      svg {
        @extend .spinner;
      }
    }

    .closeBtn {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-100%, -50%);
      height: 100px;
      width: 50px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      background: #231f20;
      line-height: 100px;
      text-align: center;
      display: block;
      text-decoration: none;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI0Ny42cHgiIGhlaWdodD0iNDQuM3B4IiB2aWV3Qm94PSIwIDAgNDcuNiA0NC4zIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0Ny42IDQ0LjMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik00Ny42LDIzLjVjLTAuMSwwLjctMC4yLDEuNC0wLjMsMi4xYy0wLjUsMy4zLTEuNyw2LjItMy41LDljLTEuNSwyLjItMy40LDQuMS01LjYsNS43Yy0yLDEuNC00LjEsMi41LTYuNSwzLjFjLTIuMSwwLjYtNC4zLDEtNi41LDAuOWMtMS44LDAtMy41LTEuNS0zLjYtMy40Yy0wLjItMiwxLjItMy44LDMtNC4xYzAuNi0wLjEsMS4zLTAuMSwxLjktMC4yYzIuOS0wLjIsNS41LTEuMiw3LjctM2MxLjYtMS4zLDIuOS0yLjgsMy45LTQuNmMwLjgtMS41LDEuMy0zLjIsMS42LTQuOWMwLjMtMi41LDAtNC44LTAuOS03LjJjLTAuOS0yLjQtMi40LTQuNC00LjMtNS45Yy0yLjEtMS43LTQuNC0yLjctNy0zLjFjLTIuOS0wLjQtNS43LDAtOC40LDEuM2MtMi4xLDEtMy44LDIuNC01LjMsNC4zYy0wLjMsMC4zLTAuNSwwLjctMC43LDEuMUMxMy4xLDE0LjcsMTMsMTUsMTMsMTUuMmMwLDAuNCwwLjIsMC43LDAuNywwLjdjMC40LDAsMC44LDAsMS4xLDBjMi41LDAsNC4xLDIuNCwzLjIsNC43Yy0wLjUsMS4yLTEuNSwxLjktMi44LDJjLTEuMSwwLjEtMi4yLDAtMy4zLDBjLTIuOCwwLTUuNSwwLTguMywwYy0yLDAtMy4xLTAuOS0zLjYtMi44YzAsMCwwLTAuMS0wLjEtMC4xYzAtNC4yLDAtOC4zLDAtMTIuNUMwLDcuMiwwLjEsNy4xLDAuMSw3YzAuMy0xLjMsMS4xLTIuMiwyLjQtMi41QzMuOCw0LjEsNS4yLDQuNiw2LDUuNkM2LjUsNi4zLDYuNyw3LDYuNyw3LjljMCwwLjQsMCwwLjcsMCwxLjFjMCwwLjIsMC4xLDAuMywwLjIsMC41YzAuMi0wLjEsMC4zLTAuMSwwLjUtMC4yQzcuNiw5LDcuOCw4LjgsNy45LDguNmMxLTEuMywyLjEtMi41LDMuNC0zLjVjMS44LTEuNSwzLjgtMi43LDYtMy41YzIuMi0wLjksNC41LTEuNCw2LjgtMS41YzQuMy0wLjIsOC4zLDAuNywxMi4xLDIuOEMzOS40LDQuNiw0Miw3LDQ0LDEwYzEuNywyLjYsMi45LDUuNSwzLjQsOC43YzAuMSwwLjcsMC4yLDEuNCwwLjMsMi4xQzQ3LjYsMjEuNyw0Ny42LDIyLjYsNDcuNiwyMy41eiIvPjwvZz48L3N2Zz4=');
      background-position: 60% 50%;
      background-repeat: no-repeat;
      background-size: 28px;
      @include media-breakpoint-down(sm) {
        top: 14px;
        right: $mobile-gutter;
        left: auto;
        transform: none;
        height: 35px;
        width: 35px;
        border-radius: 100%;
        background-size: 16px;
        background-position: center;
        z-index: 1;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzAuODkgMzcwLjkiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48dGl0bGU+Y2xvc2U8L3RpdGxlPjxnIGlkPSJDYWxxdWVfMiIgZGF0YS1uYW1lPSJDYWxxdWUgMiI+PGcgaWQ9IkxheWVyXzEiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PGcgaWQ9IkZwS3pFSy50aWYiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEyMy4wNiwxODUuNjZjLTIuOTEtMi4zLTQuNDktMy4zLTUuNzgtNC41OXEtNTUtNTQuOTQtMTEwLTEwOS45NGMtOS43OC05Ljc4LTkuNzYtMTcuNi4wNy0yNy40NFEyNS43NywyNS4zLDQ0LjE2LDYuOTJjOS4yNy05LjI1LDE3LjM3LTkuMiwyNi43My4xNlExMjYuMDYsNjIuMjIsMTgxLjE4LDExNy40YTU0LjE1LDU0LjE1LDAsMCwxLDMuNjQsNC41NGMyLTEuODksMy4zNy0zLjA3LDQuNjMtNC4zM1EyNDQuNjIsNjIuNDcsMjk5Ljc3LDcuMzFjOS43Ny05Ljc3LDE3LjYtOS43NSwyNy40NC4wOFEzNDUuNiwyNS43NywzNjQsNDQuMTdjOS4yNCw5LjI2LDkuMiwxNy4zNi0uMTcsMjYuNzNxLTU1LjE0LDU1LjE2LTExMC4zMywxMTAuMjhhMzMuNzcsMzMuNzcsMCwwLDEtNC40NywzLjJjLS4xOC43OC0uMzYsMS41Ni0uNTMsMi4zNCwxLjcxLDEsMy43MywxLjc3LDUuMTEsMy4xNHE1NS4wNSw1NC45LDExMCwxMDkuOTFjOS43OCw5Ljc4LDkuNzUsMTcuNi0uMDgsMjcuNDRsLTM2LjQxLDM2LjQyYy05Ljc0LDkuNzMtMTcuNTksOS42OC0yNy40NS0uMTdxLTU1LTU1LTEwOS45Mi0xMTBhMzcsMzcsMCwwLDEtMy40NC00Ljc5Yy0yLjM4LDIuMjYtMy44MiwzLjU3LTUuMiw0Ljk1bC0xMTAsMTEwYy05Ljc3LDkuNzctMTcuNiw5Ljc0LTI3LjQ0LS4wOVEyNS4xMywzNDUsNi41OCwzMjYuMzljLTguNzctOC44LTguNzMtMTcuMTQuMTQtMjZRNjIsMjQ1LDExNy40LDE4OS43M0MxMTguNjgsMTg4LjQ1LDEyMC4zNiwxODcuNTgsMTIzLjA2LDE4NS42NloiLz48L2c+PC9nPjwvZz48L3N2Zz4=');
        background-position: 50%;
      }
    }
  }

  &.admin-menu #quiz-container {
    top: calc(116px + 29px);

  }
}
