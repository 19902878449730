input[type="submit"] {
  @extend .my-1;
  @extend .btn;
}

input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
select,
textarea {
  @extend .form-control;
}

select {
  overflow: auto;
}

.form-type-radio {
  @extend .form-check;
}

.grippie {
  display: none;
}

input[type="submit"] + input[type="submit"] {
  margin-left: 1rem;
}
// .ui-widget-content {
//   border: 1px solid #aaa;
//   background: #fff;
//   color: $body-color;
//   border-radius: 0!important;
//   max-height: 80%;
//   overflow: auto;
// }
//
// .ui-state-focus,
// .ui-state-hover,
// .ui-widget-content .ui-state-focus,
// .ui-widget-content .ui-state-hover,
// .ui-widget-header .ui-state-focus,
// .ui-widget-header .ui-state-hover {
//   border: 0;
//   background: #eee;
//   font-weight: normal;
//   color: inherit;
//   margin: 0 !important;
//   border-radius: 0;
//   font-weight: 300;
// }

.ui-dialog {
  width: 80%!important;
  height: 80%!important;

  #nodeEmbedDialog {
    padding: 5px;
    width: 100%!important;
    height: 65% !important;
    min-height: 100%!important;
    padding-bottom: 60px;

    iframe {
      height: 100%!important;
      width: 100%!important;
    }
  }
}

input[type="text"] {
  width: 100%;
  border: 1px solid #a6a6a6;
  background-color: #f8f8f8;
  border-radius: 5px;
}

li.ui-menu-item {
  a {
    display: block;
    padding: 2px 8px;
  }
}

.opigno-quiz-app-fullscreen-link {
  display: none !important;
}

form[id^="search-api-page-search-form"] {
  position: relative;

  .form-item.form-item-keys-1 {
    display: inline-block;
    margin: 0;
    max-width: 100%;
  }

  input[type="text"] {
    background-color: #fff;
    border: 2px solid #aaa;
    border-radius: 5px;
    padding: 12px 40px 12px 20px;
    min-width: 500px;
    color: $body-color;
    font-size: 1.2em;
    @include media-breakpoint-down(sm) {
      min-width: 0;
    }
  }

  input.form-autocomplete {
    background-position: 97% center;
    line-height: 50px !important;
    height: 50px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0;
    width: 567px !important;
    max-width: 100%;
    padding: 0 2.75rem 0 37px !important;
    border: 0 !important;
    box-shadow: $box-shadow;
    @include media-breakpoint-down(sm) {
      padding: 0 1rem !important;
      height: 38px;
      max-width: calc(100% - 35px);
    }
  }

  input[type="submit"] {
    border: 0;
    background-color: #87d0c8;
    padding: 0;
    vertical-align: top;
    margin: 0 !important;
    width: 78px;
    height: 78px;
    line-height: 78px;
    border-radius: 100%;
    position: relative;
    left: -39px;
    top: -15px;
    font-size: 0;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FscXVlXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTMyLjggMTMxLjgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEzMi44IDEzMS44IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48ZGVmcz48cmVjdCBpZD0iU1ZHSURfMV8iIHdpZHRoPSIxMzIuOCIgaGVpZ2h0PSIxMzEuOCIvPjwvZGVmcz48Y2xpcFBhdGggaWQ9IlNWR0lEXzJfIj48dXNlIHhsaW5rOmhyZWY9IiNTVkdJRF8xXyIgIG92ZXJmbG93PSJ2aXNpYmxlIi8+PC9jbGlwUGF0aD48ZyBjbGlwLXBhdGg9InVybCgjU1ZHSURfMl8pIj48Y2lyY2xlIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIyMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBjeD0iODEuOCIgY3k9IjUxIiByPSI0MSIvPjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0zLjQsMTI4LjRMMy40LDEyOC40Yy00LjUtNC41LTQuNS0xMS44LDAtMTYuM0w0Myw3Mi42YzQuNS00LjUsMTEuOC00LjUsMTYuMywwYzQuNSw0LjUsNC41LDExLjgsMCwxNi4zbC0zOS42LDM5LjZDMTUuMSwxMzIuOSw3LjgsMTMyLjksMy40LDEyOC40Ii8+PC9nPjwvZz48L3N2Zz4=');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 30px;
    box-shadow: $box-shadow;
    z-index: 100;
    @include media-breakpoint-down(sm) {
      position: absolute;
      right: 0;
      left: auto;
      width: 48px;
      height: 48px;
      background-size: 22px;
      top: -6px;
    }
  }
}

#autocomplete {
  min-width: 550px;
  max-width: 100%;
}

#autocomplete,
.autocomplete-suggestions,
.ui-autocomplete {
  border: 0;
  border-top: 1px solid #ddd;
  padding: 15px 0;
  background-color: #fff;
  box-shadow: $box-shadow;
  list-style: none;
  font-size: 15px;
  border-radius: 0;

  a,
  div {
    margin-left: 37px;
    margin-right: 37px;
    color: inherit;
    border-radius: 0;
    white-space: nowrap;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    &.autocomplete-selected,
    &.ui-state-hover,
    &:hover {
      background: #572839;
      color: #fff;
      text-decoration: none;
      border: 0;
    }
  }
}

.ui-autocomplete {
  width: 600px!important;

  a {
    padding-left: 25px!important;
    padding-right: 25px!important;
  }
}

form#lumc-easy-lessons-adder-form div div.form-item {
  display: inline-block;
}

.select-hidden {
  // display: none;
  // visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  width: 100%;
}

.select-styled {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 0.5rem 0.75rem;
  transition: all 0.2s ease-in;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);

  &::after {
    content: '';
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: #9c9c9c transparent transparent transparent;
    position: absolute;
    top: 18px;
    right: 10px;
  }

  &.active,
  &:active {
    z-index: 2;
  }
}

.select-options {
  display: none;
  position: relative;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #f9f5f6;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);

  li {
    margin: 0;
    padding: 8px 12px;
    transition: all 0.15s ease-in;

    &:hover {
      background: #fff;
    }

    &[rel="hide"] {
      display: none;
    }
  }
}
