.messages {
  @extend .alert;
  width: 100%;

  &.status {
    @extend .alert-success;
  }

  &.warning {
    @extend .alert-warning;
  }

  &.error {
    @extend .alert-danger;
  }
}
