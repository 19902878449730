ul.pager {
  margin-top: 60px;
  text-align: right;
  position: relative;
  font-size: 0;

  li {
    position: relative;
    font-size: 1rem;
    padding: 0.5em 0.8em;
    background: transparent!important;

    &.first,
    &.pager-previous + li {
      &::after {
        display: none;
      }
    }

    a {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding: 0;
      line-height: 26px;
      text-decoration: none;

      &:focus,
      &:hover {
        background-color: transparent;
      }

      &.active {
        font-weight: 700;
      }
    }

    &.pager-first,
    &.pager-last {
      display: none;
    }

    &.pager-next > a,
    &.pager-next > span {
      float: none;
    }
  }
}
