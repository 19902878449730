body.page-node-review {
  #edit-anatomical-correctness,
  #edit-educational-quality,
  #edit-aesthetic-quality {
    .form-item {
      display: inline-block;

      & + .form-item {
        margin-left: 8px;
      }
    }
  }
}
