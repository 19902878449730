body {
  // background: linear-gradient(to bottom, #2c7e8f, #93c3ca 50%, #82131f);
  background-attachment: fixed;

  div#background-image {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-repeat: no-repeat;
  }

  &.admin-menu {
    div#background-image {
      top: 29px;
    }
  }

  h1.title {
    &.vertical {
      transform: rotateZ(-90deg) translateX(-100%) translateY(100%);
      transform-origin: 0 100%;
      position: absolute;
      margin: -45px 0 0;
      font-size: 225%;
      white-space: nowrap;

      + div#content-header {
        margin-left: 90px;
      }

      + div#content-header + div#content-area {
        margin-left: 90px;
      }
    }
  }

  div#main {
    padding-bottom: 2em;
  }
}
//.field-name-og-group-ref {
//    display: none;
//}

#content-area {}

fieldset.group-di-classification {
  margin-bottom: 10px;
}

fieldset[id^='edit-lumc-homepage-settings-images'] {
  border: 1px solid #bbb;
  padding: 0 20px;
  margin-bottom: 15px;
}

input.form-submit {
  margin-right: 1em;
}

fieldset.form-wrapper > legend {
  font-weight: 700;
}

label.option {
  font-weight: 300;
}

.h5p-content {
  margin-bottom: 0.5em;

  .h5p-flashcards {
    .h5p-description {
      display: none;
    }
  }
}

.text-format-wrapper {
  .filter-wrapper {
    display: none;
  }
}

.field-type-text-with-summary {
  .form-type-textarea {
    margin-bottom: 24px;
  }
}

.link-embeded-link a {
  word-break: break-all;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}
