body.node-type-data-item:not(.page-node-add-data-item):not(.page-node-edit),
body.page-node-add-data-item:not(.page-node-add-data-item):not(.page-node-edit) {
  background: #f6f0f0 !important;

  .right-menu-open {
    position: fixed;
  }

  .iframe-wrapper {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  iframe.pdf {
    position: relative;
    z-index: 1;
  }

  section#content {
    h1.title {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      background-color: #125477;
      color: #fff;
      padding: 28px 110px 28px 380px;
      text-align: center;
      margin: 0;
      font-size: 30px;
      line-height: 1;
      z-index: 101;
      height: 116px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        height: auto;
        padding: 1rem;
        font-size: 17px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }

      a#logo {
        position: absolute;
        left: 100px;
        height: 21px;
        font-size: 0;
        @include media-breakpoint-down(sm) {
          display: block;
          width: 22px;
          height: 22px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTExLjk5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmhvbWU8L3RpdGxlPjxnIGlkPSJDYWxxdWVfMiIgZGF0YS1uYW1lPSJDYWxxdWUgMiI+PGcgaWQ9IkxheWVyXzEiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PGcgaWQ9IllxV2dkeC50aWYiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTI1MCwwaDEzYzExLjQ2LDMuNzcsMTkuNTQsMTIuMTQsMjcuNzQsMjAuMzVxMjguNDQsMjguNDQsNTYuOTEsNTYuODZjMS4xOSwxLjE5LDIuNDQsMi4zMyw0LjM3LDQuMTYsMC02Ljg4LDAtMTIuNTMsMC0xOC4xOEEzMS43NCwzMS43NCwwLDAsMSwzODMuMzMsMzJjMTAuNDktLjA3LDIxLDAsMzEuNDgsMEM0MzQuMzgsMzIsNDQ4LDQ1LjY4LDQ0OCw2NS4zNGMwLDM1LjgsMCw3MS42MS0uMDgsMTA3LjQyYTEwLjQ3LDEwLjQ3LDAsMCwwLDMuNDYsOC4yM2MxMy42NSwxMy40NCwyNy4xMywyNyw0MC43LDQwLjU2LDguMTIsOC4wOSwxNi4xOSwxNi4xOSwxOS45NCwyNy40NXYxNGMtLjY5LDEuNjItMS40NCwzLjIyLTIuMDgsNC44NkEzMS4yLDMxLjIsMCwwLDEsNDgxLDI4OGMtNi4xNi4wNi0xMi4zMywwLTE4LjUsMEg0NDh2Ni42OHEwLDkyLjI1LDAsMTg0LjQ5YzAsMTktMTMuNzksMzIuODEtMzIuNzEsMzIuODJxLTQ1LDAtOTAsMGMtMS42MiwwLTMuMjMtLjEzLTUuMjUtLjIyVjM1NS4wNWMwLTIxLjc2LTEzLjQtMzUuMDYtMzUuMjktMzUuMDdIMjI3LjI0Yy0yMS45LDAtMzUuMjUsMTMuMjktMzUuMjYsMzUuMDhWNTEySDk3LjcyQzc3LjQxLDUxMiw2NCw0OTguNTMsNjQsNDc4LjE2VjI4OGMtMTEuNDEsMC0yMi4yMS4wNi0zMywwQTMxLDMxLDAsMCwxLDEuOTMsMjY3LjRDMS4zNywyNjUuOS42NSwyNjQuNDYsMCwyNjNWMjQ5YzMuMzQtOS4yMyw5LjE5LTE2LjcxLDE2LjA2LTIzLjU3UTEyMC45MywxMjAuNzIsMjI1LjcxLDE1LjkzQzIzMi43Miw4LjkxLDI0MC4yLDIuNzEsMjUwLDBaIi8+PC9nPjwvZz48L2c+PC9zdmc+');
          background-repeat: no-repeat;
          left: $mobile-gutter;
        }

        img {
          height: inherit;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }

    a#logo + .node-links {
      position: absolute;
      right: 120px;
      top: 50%;
      transform: translateY(-50%);
      @include media-breakpoint-down(sm) {
        position: static;
        transform: none;
      }

      .institution {
        height: 30px;
        margin-top: -5px;
      }

      a {
        display: inline-block;
        height: 25px;
        width: 25px;
        background-position: center;
        background-repeat: no-repeat;
        @include media-breakpoint-down(sm) {
          vertical-align: middle;
        }

        &.download {
          background-color: #16776e;
          background-image: url('../images/picto-download.png');
        }

        &.comment {
          background-color: #000;
          background-image: url('../images/picto-comment.png');
          background-size: 15px auto;
        }
      }
    }

    div#content-area {
      padding-top: 116px;
      @include media-breakpoint-down(sm) {
        padding-top: calc(49px + 1rem);
      }
    }

    .video-js {
      // width: 200%;
      // height: 600px;
      // max-width: 100%;
      // max-height: calc(100vh - 100px);
    }

    iframe {
      width: 100%;
      min-height: calc(100vh - 100px);
    }

    .field-type-image {
      img {
        max-height: calc(100vh - 100px);
        width: auto;
      }
    }

    table#field-anatomical-structures-values td {
      padding: 0 0.75rem;
    }

    .user-picture {
      img {
        width: auto;
        max-height: 33px;
      }
    }

    .container-fluid {
      padding-left: 100px;
      @include media-breakpoint-down(sm) {
        padding-left: $mobile-gutter;
      }
    }

    .left-col {
      width: 400px;
      float: left;
    }

    .right-col {
      width: calc(100% - 400px);
      float: left;
    }

    .links {
      .review {
        background-color: #1db759;
        height: 25px;
        line-height: 25px;
        border-radius: 12px;
        display: inline-block;
        vertical-align: top;
        color: #fff;
        padding: 0 15px;
        font-size: 0.7rem;
        text-decoration: none;
        text-transform: uppercase;

        &.not-reviewed {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

      .comment {
        display: inline-block;
        height: 25px;
        width: 25px;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: top;
        background-color: #000;
        background-image: url('../images/picto-comment.png');
        background-size: 15px auto;
        font-size: 0;
      }

      .download {
        display: inline-block;
        height: 25px;
        width: 25px;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: top;
        background-color: #16776e;
        background-image: url('../images/picto-download.png');
        font-size: 0;
      }

      .clone {
        background-color: rgba(0, 0, 0, 0.5);
        height: 25px;
        line-height: 25px;
        border-radius: 12px;
        display: inline-block;
        vertical-align: top;
        color: #fff;
        padding: 0 15px;
        font-size: 0.7rem;
        text-decoration: none;
        text-transform: uppercase;
      }

      .information {
        background-color: #741164;
        height: 25px;
        width: 25px;
        display: inline-block;
        vertical-align: top;
        border-radius: 100%;
        cursor: pointer;
        background-image: url('../images/picto-info.png');
        background-position: center;
        background-repeat: no-repeat;
        font-size: 0;
      }
    }
  }

  .dataItemPanel {
    position: fixed;
    top: 116px;
    bottom: 0;
    right: 0;
    width: 475px;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 101;
    color: #fff;
    font-size: 12px;
    @include media-breakpoint-down(sm) {
      top: 49px;
    }

    .italic {
      font-style: italic;
    }

    .closeBtn {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-100%, -50%);
      height: 100px;
      width: 50px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      background: #231f20;
      line-height: 100px;
      text-align: center;
      display: block;
      text-decoration: none;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI0Ny42cHgiIGhlaWdodD0iNDQuM3B4IiB2aWV3Qm94PSIwIDAgNDcuNiA0NC4zIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0Ny42IDQ0LjMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik00Ny42LDIzLjVjLTAuMSwwLjctMC4yLDEuNC0wLjMsMi4xYy0wLjUsMy4zLTEuNyw2LjItMy41LDljLTEuNSwyLjItMy40LDQuMS01LjYsNS43Yy0yLDEuNC00LjEsMi41LTYuNSwzLjFjLTIuMSwwLjYtNC4zLDEtNi41LDAuOWMtMS44LDAtMy41LTEuNS0zLjYtMy40Yy0wLjItMiwxLjItMy44LDMtNC4xYzAuNi0wLjEsMS4zLTAuMSwxLjktMC4yYzIuOS0wLjIsNS41LTEuMiw3LjctM2MxLjYtMS4zLDIuOS0yLjgsMy45LTQuNmMwLjgtMS41LDEuMy0zLjIsMS42LTQuOWMwLjMtMi41LDAtNC44LTAuOS03LjJjLTAuOS0yLjQtMi40LTQuNC00LjMtNS45Yy0yLjEtMS43LTQuNC0yLjctNy0zLjFjLTIuOS0wLjQtNS43LDAtOC40LDEuM2MtMi4xLDEtMy44LDIuNC01LjMsNC4zYy0wLjMsMC4zLTAuNSwwLjctMC43LDEuMUMxMy4xLDE0LjcsMTMsMTUsMTMsMTUuMmMwLDAuNCwwLjIsMC43LDAuNywwLjdjMC40LDAsMC44LDAsMS4xLDBjMi41LDAsNC4xLDIuNCwzLjIsNC43Yy0wLjUsMS4yLTEuNSwxLjktMi44LDJjLTEuMSwwLjEtMi4yLDAtMy4zLDBjLTIuOCwwLTUuNSwwLTguMywwYy0yLDAtMy4xLTAuOS0zLjYtMi44YzAsMCwwLTAuMS0wLjEtMC4xYzAtNC4yLDAtOC4zLDAtMTIuNUMwLDcuMiwwLjEsNy4xLDAuMSw3YzAuMy0xLjMsMS4xLTIuMiwyLjQtMi41QzMuOCw0LjEsNS4yLDQuNiw2LDUuNkM2LjUsNi4zLDYuNyw3LDYuNyw3LjljMCwwLjQsMCwwLjcsMCwxLjFjMCwwLjIsMC4xLDAuMywwLjIsMC41YzAuMi0wLjEsMC4zLTAuMSwwLjUtMC4yQzcuNiw5LDcuOCw4LjgsNy45LDguNmMxLTEuMywyLjEtMi41LDMuNC0zLjVjMS44LTEuNSwzLjgtMi43LDYtMy41YzIuMi0wLjksNC41LTEuNCw2LjgtMS41YzQuMy0wLjIsOC4zLDAuNywxMi4xLDIuOEMzOS40LDQuNiw0Miw3LDQ0LDEwYzEuNywyLjYsMi45LDUuNSwzLjQsOC43YzAuMSwwLjcsMC4yLDEuNCwwLjMsMi4xQzQ3LjYsMjEuNyw0Ny42LDIyLjYsNDcuNiwyMy41eiIvPjwvZz48L3N2Zz4=');
      background-position: 60% 50%;
      background-repeat: no-repeat;
      background-size: 28px;
      @include media-breakpoint-down(sm) {
        top: 14px;
        right: $mobile-gutter;
        left: auto;
        transform: none;
        height: 35px;
        width: 35px;
        border-radius: 100%;
        background-size: 16px;
        background-position: center;
        z-index: 1;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzAuODkgMzcwLjkiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48dGl0bGU+Y2xvc2U8L3RpdGxlPjxnIGlkPSJDYWxxdWVfMiIgZGF0YS1uYW1lPSJDYWxxdWUgMiI+PGcgaWQ9IkxheWVyXzEiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PGcgaWQ9IkZwS3pFSy50aWYiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEyMy4wNiwxODUuNjZjLTIuOTEtMi4zLTQuNDktMy4zLTUuNzgtNC41OXEtNTUtNTQuOTQtMTEwLTEwOS45NGMtOS43OC05Ljc4LTkuNzYtMTcuNi4wNy0yNy40NFEyNS43NywyNS4zLDQ0LjE2LDYuOTJjOS4yNy05LjI1LDE3LjM3LTkuMiwyNi43My4xNlExMjYuMDYsNjIuMjIsMTgxLjE4LDExNy40YTU0LjE1LDU0LjE1LDAsMCwxLDMuNjQsNC41NGMyLTEuODksMy4zNy0zLjA3LDQuNjMtNC4zM1EyNDQuNjIsNjIuNDcsMjk5Ljc3LDcuMzFjOS43Ny05Ljc3LDE3LjYtOS43NSwyNy40NC4wOFEzNDUuNiwyNS43NywzNjQsNDQuMTdjOS4yNCw5LjI2LDkuMiwxNy4zNi0uMTcsMjYuNzNxLTU1LjE0LDU1LjE2LTExMC4zMywxMTAuMjhhMzMuNzcsMzMuNzcsMCwwLDEtNC40NywzLjJjLS4xOC43OC0uMzYsMS41Ni0uNTMsMi4zNCwxLjcxLDEsMy43MywxLjc3LDUuMTEsMy4xNHE1NS4wNSw1NC45LDExMCwxMDkuOTFjOS43OCw5Ljc4LDkuNzUsMTcuNi0uMDgsMjcuNDRsLTM2LjQxLDM2LjQyYy05Ljc0LDkuNzMtMTcuNTksOS42OC0yNy40NS0uMTdxLTU1LTU1LTEwOS45Mi0xMTBhMzcsMzcsMCwwLDEtMy40NC00Ljc5Yy0yLjM4LDIuMjYtMy44MiwzLjU3LTUuMiw0Ljk1bC0xMTAsMTEwYy05Ljc3LDkuNzctMTcuNiw5Ljc0LTI3LjQ0LS4wOVEyNS4xMywzNDUsNi41OCwzMjYuMzljLTguNzctOC44LTguNzMtMTcuMTQuMTQtMjZRNjIsMjQ1LDExNy40LDE4OS43M0MxMTguNjgsMTg4LjQ1LDEyMC4zNiwxODcuNTgsMTIzLjA2LDE4NS42NloiLz48L2c+PC9nPjwvZz48L3N2Zz4=');
        background-position: 50%;
      }
    }

    .nodeInfo {
      position: relative;
    }

    .upperpart {
      padding: 20px 35px 0;
      @include media-breakpoint-down(sm) {
        padding: 20px $mobile-gutter 0;
      }
    }

    .comment-back {
      display: block;
      height: 23px;
      width: 23px;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI0Ny42cHgiIGhlaWdodD0iNDQuM3B4IiB2aWV3Qm94PSIwIDAgNDcuNiA0NC4zIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0Ny42IDQ0LjMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik00Ny42LDIzLjVjLTAuMSwwLjctMC4yLDEuNC0wLjMsMi4xYy0wLjUsMy4zLTEuNyw2LjItMy41LDljLTEuNSwyLjItMy40LDQuMS01LjYsNS43Yy0yLDEuNC00LjEsMi41LTYuNSwzLjFjLTIuMSwwLjYtNC4zLDEtNi41LDAuOWMtMS44LDAtMy41LTEuNS0zLjYtMy40Yy0wLjItMiwxLjItMy44LDMtNC4xYzAuNi0wLjEsMS4zLTAuMSwxLjktMC4yYzIuOS0wLjIsNS41LTEuMiw3LjctM2MxLjYtMS4zLDIuOS0yLjgsMy45LTQuNmMwLjgtMS41LDEuMy0zLjIsMS42LTQuOWMwLjMtMi41LDAtNC44LTAuOS03LjJjLTAuOS0yLjQtMi40LTQuNC00LjMtNS45Yy0yLjEtMS43LTQuNC0yLjctNy0zLjFjLTIuOS0wLjQtNS43LDAtOC40LDEuM2MtMi4xLDEtMy44LDIuNC01LjMsNC4zYy0wLjMsMC4zLTAuNSwwLjctMC43LDEuMUMxMy4xLDE0LjcsMTMsMTUsMTMsMTUuMmMwLDAuNCwwLjIsMC43LDAuNywwLjdjMC40LDAsMC44LDAsMS4xLDBjMi41LDAsNC4xLDIuNCwzLjIsNC43Yy0wLjUsMS4yLTEuNSwxLjktMi44LDJjLTEuMSwwLjEtMi4yLDAtMy4zLDBjLTIuOCwwLTUuNSwwLTguMywwYy0yLDAtMy4xLTAuOS0zLjYtMi44YzAsMCwwLTAuMS0wLjEtMC4xYzAtNC4yLDAtOC4zLDAtMTIuNUMwLDcuMiwwLjEsNy4xLDAuMSw3YzAuMy0xLjMsMS4xLTIuMiwyLjQtMi41QzMuOCw0LjEsNS4yLDQuNiw2LDUuNkM2LjUsNi4zLDYuNyw3LDYuNyw3LjljMCwwLjQsMCwwLjcsMCwxLjFjMCwwLjIsMC4xLDAuMywwLjIsMC41YzAuMi0wLjEsMC4zLTAuMSwwLjUtMC4yQzcuNiw5LDcuOCw4LjgsNy45LDguNmMxLTEuMywyLjEtMi41LDMuNC0zLjVjMS44LTEuNSwzLjgtMi43LDYtMy41YzIuMi0wLjksNC41LTEuNCw2LjgtMS41YzQuMy0wLjIsOC4zLDAuNywxMi4xLDIuOEMzOS40LDQuNiw0Miw3LDQ0LDEwYzEuNywyLjYsMi45LDUuNSwzLjQsOC43YzAuMSwwLjcsMC4yLDEuNCwwLjMsMi4xQzQ3LjYsMjEuNyw0Ny42LDIyLjYsNDcuNiwyMy41eiIvPjwvZz48L3N2Zz4=');
      background-position: 60% 50%;
      background-repeat: no-repeat;
      background-size: 15px;
      position: absolute;
      right: 0;
      top: 0;
      text-decoration: none;
    }

    .dataItemPanelContent {
      overflow: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      height: 100%;
      @include media-breakpoint-down(sm) {
        margin-top: 45px;
      }

      .titlePart {
        flex: 1;
      }

      .institutionPart {
        text-align: right;
        margin-left: 15px;

        img {
          height: 35px;
          width: auto;
          max-width: 100%;
        }
      }

      .upperpart {
        padding: 20px 35px 0;
        @include media-breakpoint-down(sm) {
          padding: 20px $mobile-gutter 0;
        }
      }

      .lowerpart {
        padding: 0 35px 20px;
        overflow: hidden;
        flex: 1;
        @include media-breakpoint-down(sm) {
          padding: 0 $mobile-gutter 20px;
        }

        &.mCustomScrollbar {
          padding-right: 0;
          padding-bottom: 0;
          @include media-breakpoint-down(sm) {
            padding-right: $mobile-gutter;
          }

          .mCSB_scrollTools {
            width: 10px;
          }

          .mCSB_inside > .mCSB_container {
            margin-right: 35px;
            @include media-breakpoint-down(sm) {
              margin-right: 0;
            }
          }

          .mCSB_scrollTools .mCSB_draggerContainer {
            background-color: #7f7b7a;
          }

          .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
            width: 8px;
          }

          .mCSB_scrollTools .mCSB_draggerRail {
            display: none;
          }
        }
      }

      .educationalLevel {
        span {
          color: #777;

          &.active {
            color: #fff;
          }
        }
      }

      .imageDirection {
        img {
          height: 4.5em;
          width: auto;
          max-width: none;
        }
      }

      .license {
        img {
          height: 2.6em;
          width: auto;
          max-width: none;
        }
      }

      .itemTitle {
        line-height: 1.2;
      }

      .author {
        line-height: 1.2;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      .comments-list {
        i {
          font-family: serif;
        }
      }

      .credits,
      .institution {
        font-size: 10px;
      }

      .image {
        height: 272px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-color: #fff;
        color: #999;
        padding: 15px;
        margin-top: 0.5rem;

        .image-link {
          position: absolute;
          display: block;
          height: calc(100% - 52px);
          left: 0;
          right: 0;
          top: 0;
        }

        &[style*="watermark"] {
          background-position: center 25%;
          background-size: auto 70%;
        }

        .info {
          position: absolute;
          bottom: 53px;
          left: 42px;
          background-color: #fff;
          border-radius: 15px 15px 15px 0;
          padding: 15px;
          color: #000;
          max-width: 225px;
          box-shadow: $box-shadow;
          font-size: 13px;

          &::after {
            content: '';
            display: block;
            width: 29px;
            height: 21px;
            background-image: url('../images/picto-info-bottom.png');
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            bottom: -11px;
            left: -11px;
          }
        }

        .node-links {
          position: absolute;
          bottom: 10px;
          left: 10px;
        }

        .infoTrigger {
          background-color: #741164;
          height: 25px;
          width: 25px;
          display: inline-block;
          vertical-align: top;
          border-radius: 100%;
          cursor: pointer;
          background-image: url('../images/picto-info.png');
          background-position: center;
          background-repeat: no-repeat;
        }

        .download {
          background-color: #16776e;
          height: 25px;
          width: 25px;
          display: inline-block;
          vertical-align: top;
          border-radius: 100%;
          background-image: url('../images/picto-download.png');
          background-position: center;
          background-repeat: no-repeat;
        }

        .comment {
          background-color: #000;
          height: 25px;
          width: 25px;
          display: inline-block;
          vertical-align: top;
          border-radius: 100%;
          background-size: 14px;
          background-image: url('../images/picto-comment.png');
          background-position: center;
          background-repeat: no-repeat;
        }

        .review {
          background-color: #1db759;
          height: 25px;
          line-height: 25px;
          border-radius: 12px;
          display: inline-block;
          vertical-align: top;
          color: #fff;
          padding: 0 15px;
          font-size: 0.7rem;
          text-decoration: none;
          text-transform: uppercase;

          &.not-reviewed {
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        .comment,
        .infoTrigger,
        .review {
          &.active {
            box-shadow: 0 0 5px 2px rgba(255,255,255,.7);
          }
        }

        .search {
          background-color: rgba(0,0,0,.5);
          border-radius: 8px;
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: #fff;
          text-transform: uppercase;
          line-height: 32px;
          padding: 0 12px;
          font-size: 10px;

          &:hover {
            text-decoration: none;
            background-color: rgba(0,0,0,.75);
          }
        }

        .manage-item {
          background-color: rgba(0, 0, 0, 0.5);
          color: #fff;
          height: 25px;
          line-height: 25px;
          border-radius: 12px;
          padding: 0 15px;
          font-size: 0.7rem;
          text-decoration: none;
          display: inline-block;
          vertical-align: top;
          text-transform: uppercase;
        }
      }

      .nodeDescription {
        font-style: italic;

        a {
          font-style: normal;
        }

        strong {
          font-style: normal;
        }
      }

      .nodeInfo {
        font-size: 12px;
        padding-bottom: 30px;

        .comment-back {
          display: block;
          height: 23px;
          width: 23px;
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI0Ny42cHgiIGhlaWdodD0iNDQuM3B4IiB2aWV3Qm94PSIwIDAgNDcuNiA0NC4zIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0Ny42IDQ0LjMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik00Ny42LDIzLjVjLTAuMSwwLjctMC4yLDEuNC0wLjMsMi4xYy0wLjUsMy4zLTEuNyw2LjItMy41LDljLTEuNSwyLjItMy40LDQuMS01LjYsNS43Yy0yLDEuNC00LjEsMi41LTYuNSwzLjFjLTIuMSwwLjYtNC4zLDEtNi41LDAuOWMtMS44LDAtMy41LTEuNS0zLjYtMy40Yy0wLjItMiwxLjItMy44LDMtNC4xYzAuNi0wLjEsMS4zLTAuMSwxLjktMC4yYzIuOS0wLjIsNS41LTEuMiw3LjctM2MxLjYtMS4zLDIuOS0yLjgsMy45LTQuNmMwLjgtMS41LDEuMy0zLjIsMS42LTQuOWMwLjMtMi41LDAtNC44LTAuOS03LjJjLTAuOS0yLjQtMi40LTQuNC00LjMtNS45Yy0yLjEtMS43LTQuNC0yLjctNy0zLjFjLTIuOS0wLjQtNS43LDAtOC40LDEuM2MtMi4xLDEtMy44LDIuNC01LjMsNC4zYy0wLjMsMC4zLTAuNSwwLjctMC43LDEuMUMxMy4xLDE0LjcsMTMsMTUsMTMsMTUuMmMwLDAuNCwwLjIsMC43LDAuNywwLjdjMC40LDAsMC44LDAsMS4xLDBjMi41LDAsNC4xLDIuNCwzLjIsNC43Yy0wLjUsMS4yLTEuNSwxLjktMi44LDJjLTEuMSwwLjEtMi4yLDAtMy4zLDBjLTIuOCwwLTUuNSwwLTguMywwYy0yLDAtMy4xLTAuOS0zLjYtMi44YzAsMCwwLTAuMS0wLjEtMC4xYzAtNC4yLDAtOC4zLDAtMTIuNUMwLDcuMiwwLjEsNy4xLDAuMSw3YzAuMy0xLjMsMS4xLTIuMiwyLjQtMi41QzMuOCw0LjEsNS4yLDQuNiw2LDUuNkM2LjUsNi4zLDYuNyw3LDYuNyw3LjljMCwwLjQsMCwwLjcsMCwxLjFjMCwwLjIsMC4xLDAuMywwLjIsMC41YzAuMi0wLjEsMC4zLTAuMSwwLjUtMC4yQzcuNiw5LDcuOCw4LjgsNy45LDguNmMxLTEuMywyLjEtMi41LDMuNC0zLjVjMS44LTEuNSwzLjgtMi43LDYtMy41YzIuMi0wLjksNC41LTEuNCw2LjgtMS41YzQuMy0wLjIsOC4zLDAuNywxMi4xLDIuOEMzOS40LDQuNiw0Miw3LDQ0LDEwYzEuNywyLjYsMi45LDUuNSwzLjQsOC43YzAuMSwwLjcsMC4yLDEuNCwwLjMsMi4xQzQ3LjYsMjEuNyw0Ny42LDIyLjYsNDcuNiwyMy41eiIvPjwvZz48L3N2Zz4=');
          background-position: 60% 50%;
          background-repeat: no-repeat;
          background-size: 15px;
          position: absolute;
          right: 0;
          top: 24px;
          text-decoration: none;
        }

        &.info {
          div + div.title {
            margin-top: 0.5rem;
          }
        }

        h3 {
          font-size: 16px;
        }

        a {
          color: inherit;
        }

        .separator {
          height: 1px;
          background-color: #999;
          margin: 30px 0;
        }

        .bottom,
        .top {
          display: flex;
        }

        .top {
          flex-direction: column;
        }

        .left-col,
        .right-col {
          flex: 1;
        }

        .left-col {
          margin-right: 10px;
        }

        .right-col {
          margin-left: 10px;
        }

        .title-value {
          display: flex;

          .title {
            flex: 1;
          }

          .value {
            flex: 0 1 35px;
          }
        }
      }
    }

    a {
      color: #fff;
    }

    h2.title {
      display: none;
    }

    .title {
      font-weight: 700;
      font-size: 1.2em;
    }

    article.comment {
      border-bottom: 1px solid #999;
      margin-bottom: 1rem !important;
      padding-bottom: 1rem;

      .field-name-comment-body {
        font-style: italic;
      }

      .user-picture {
        display: none;
      }

      .links {
        display: none;
      }
    }
  }

  &.admin-menu section#content .dataItemPanel {
    @include media-breakpoint-up(md) {
      top: 145px;
    }
  }

  &.admin-menu section#content h1.title {
    top: 29px;
    @include media-breakpoint-down(sm) {
      top: 0;
    }
  }
}
