$region-color: #084a6d;
$region-tabs-color: #258bd6;
$systems-color: #8e3223;
$systems-tabs-color: #ca4a44;

.block-lumc-anatomical-structures {
  position: static;
}

#structure-container {
  background-color: #fff;
  width: 590px;
  max-width: 100%;
  position: fixed;
  z-index: 102;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -2px 0 5px 0 rgba(0, 0, 0, .2);

  body.admin-menu & {
    top: 29px;
    @include media-breakpoint-down(sm) {
      top: 0;
    }
  }

  .structure-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
    height: 100px;
    width: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background: #d4e0e3;
    line-height: 100px;
    text-align: center;
    display: block;
    text-decoration: none;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0Ny42IDQ0LjMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQ3LjYgNDQuMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0iIzQyMUIyOSIgZD0iTTQ3LjYsMjMuNWMtMC4xLDAuNy0wLjIsMS40LTAuMywyLjFjLTAuNSwzLjMtMS43LDYuMi0zLjUsOWMtMS41LDIuMi0zLjQsNC4xLTUuNiw1LjdjLTIsMS40LTQuMSwyLjUtNi41LDMuMWMtMi4xLDAuNi00LjMsMS02LjUsMC45Yy0xLjgsMC0zLjUtMS41LTMuNi0zLjRjLTAuMi0yLDEuMi0zLjgsMy00LjFjMC42LTAuMSwxLjMtMC4xLDEuOS0wLjJjMi45LTAuMiw1LjUtMS4yLDcuNy0zYzEuNi0xLjMsMi45LTIuOCwzLjktNC42YzAuOC0xLjUsMS4zLTMuMiwxLjYtNC45YzAuMy0yLjUsMC00LjgtMC45LTcuMmMtMC45LTIuNC0yLjQtNC40LTQuMy01LjljLTIuMS0xLjctNC40LTIuNy03LTMuMWMtMi45LTAuNC01LjcsMC04LjQsMS4zYy0yLjEsMS0zLjgsMi40LTUuMyw0LjNjLTAuMywwLjMtMC41LDAuNy0wLjcsMS4xQzEzLjEsMTQuNywxMywxNSwxMywxNS4yYzAsMC40LDAuMiwwLjcsMC43LDAuN2MwLjQsMCwwLjgsMCwxLjEsMGMyLjUsMCw0LjEsMi40LDMuMiw0LjdjLTAuNSwxLjItMS41LDEuOS0yLjgsMmMtMS4xLDAuMS0yLjIsMC0zLjMsMGMtMi44LDAtNS41LDAtOC4zLDBjLTIsMC0zLjEtMC45LTMuNi0yLjhjMCwwLDAtMC4xLTAuMS0wLjFjMC00LjIsMC04LjMsMC0xMi41QzAsNy4yLDAuMSw3LjEsMC4xLDdjMC4zLTEuMywxLjEtMi4yLDIuNC0yLjVDMy44LDQuMSw1LjIsNC42LDYsNS42QzYuNSw2LjMsNi43LDcsNi43LDcuOWMwLDAuNCwwLDAuNywwLDEuMWMwLDAuMiwwLjEsMC4zLDAuMiwwLjVjMC4yLTAuMSwwLjMtMC4xLDAuNS0wLjJDNy42LDksNy44LDguOCw3LjksOC42YzEtMS4zLDIuMS0yLjUsMy40LTMuNWMxLjgtMS41LDMuOC0yLjcsNi0zLjVjMi4yLTAuOSw0LjUtMS40LDYuOC0xLjVjNC4zLTAuMiw4LjMsMC43LDEyLjEsMi44QzM5LjQsNC42LDQyLDcsNDQsMTBjMS43LDIuNiwyLjksNS41LDMuNCw4LjdjMC4xLDAuNywwLjIsMS40LDAuMywyLjFDNDcuNiwyMS43LDQ3LjYsMjIuNiw0Ny42LDIzLjV6Ii8+PC9nPjwvc3ZnPg==');
    background-position: 60% 50%;
    background-repeat: no-repeat;
    background-size: 28px;
    @include media-breakpoint-down(sm) {
      top: 23px;
      right: 11px;
      left: auto;
      transform: none;
      height: 35px;
      width: 35px;
      border-radius: 100%;
      background-size: 20px;
      background-position: center;
    }
  }

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -32.5px;
    margin-left: -32.5px;

    svg {
      @extend .spinner;
    }
  }

  [ng-include="template"] {
    height: 100vh;
    overflow: auto;

    body.admin-menu & {
      height: calc(100vh - 29px);
    }
  }

  .structure-title {
    text-align: center;
    font-size: 1.2em;
    padding: 11px 1rem;
    color: #fff;
    font-weight: 600;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      color: inherit;
    }

    &.region {
      background-color: $region-color;
    }

    &.systems {
      background-color: $systems-color;
    }

    .subtitle {
      font-style: italic;
      font-weight: 400;
      font-family: serif;
    }
  }

  ul {
    padding: 40px 0;
    margin-top: 0;
    margin-bottom: 0;
    max-height: 100%;

    li {
      list-style: none;
      text-align: center;
      margin-bottom: 1em;
      min-height: 225px;

      &.no-image {
        min-height: auto;
      }

      &.list-style {
        list-style: disc;
        margin-left: 50px;
        text-align: left;
        margin-bottom: 0;
      }

      &.title {
        margin-bottom: 2em;
      }

      &.taxonomy + .structure {
        margin-top: 1em;
      }

      a {
        display: inline-block;
        color: #000;
        text-decoration: none;
        font-weight: 500;

        img {
          font-size: 16px;
          max-width: 100%;
          height: 125px;
          margin-bottom: 0.5rem;
        }

        .title {
          margin-top: -5px;
        }

        &.structure {
          margin-left: 15px;
        }

        &.region-link {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &.has-images {
      li {
        a {
          min-height: 160px;
        }
      }
    }

    a.back,
    a.back-main {
      margin-left: 30px;
      margin-top: 20px;
      display: block;
    }
  }

  table {
    margin-bottom: 0;

    &.relations {
      padding: 0;

      td.first-child {
        width: 200px;
        @include media-breakpoint-down(sm) {
          width: auto;
          min-width: 121px;
        }
      }

      td.burger {
        width: 55px;
        position: relative;
      }

      .btn-link,
      a {
        color: inherit;
        background-color: transparent;
      }

      button:not(.btn-link) {
        $barHeight: 2px;
        $space: 7px;
        $width: 25px;
        $barColor: #000;
        width: $width;
        cursor: pointer;
        height: $barHeight * 3 + $space * 2;
        background-color: transparent;
        border: 0;
        padding: 3px;
        box-sizing: content-box;
        position: absolute;

        .burger-icon {
          display: inline-block;
          height: 100%;
          position: relative;
          width: $width;

          .line {
            position: absolute;
            display: block;
            height: $barHeight;
            width: $width;
            background-color: $barColor;
            transform: translate3d(0, 0, 0);

            &.top {
              top: $barHeight;
            }

            &.middle {
              top: $barHeight + $space;
            }

            &.bottom {
              top: $barHeight + 2 * $space;
            }
          }
        }
      }
    }
  }

  .header {
    $darkenActivePart: 8%;
    color: #fff;
    text-align: center;
    padding: 8px 15px;
    display: block;
    cursor: pointer;

    &.region {
      background: linear-gradient($region-tabs-color, darken($region-color, 0.005));

      &.active {
        background: darken($region-color, $darkenActivePart);
      }
    }

    &.systems {
      background: linear-gradient($systems-tabs-color, darken($systems-color, 0.005));

      &.active {
        background: darken($systems-color, $darkenActivePart);
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  div#accordion {
    section {
      .content {
        padding: 5px 0;

        a:not(.text) {
          min-height: 200px;
        }
      }
    }
  }
}
