body.page-my-courses {
  background: #f6f0f0 !important;

  section#content {
    h1.title {
      background-color: #125477;
      color: #fff;
      padding: 28px 15px;
      text-align: center;
      margin: 0;
      font-size: 30px;
      line-height: 1;
      z-index: 101;
      height: 116px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        height: auto;
        padding: 1rem;
        font-size: 17px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }

      a#logo {
        position: absolute;
        left: 100px;
        height: 21px;
        font-size: 0;
        @include media-breakpoint-down(sm) {
          display: block;
          width: 22px;
          height: 22px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTExLjk5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmhvbWU8L3RpdGxlPjxnIGlkPSJDYWxxdWVfMiIgZGF0YS1uYW1lPSJDYWxxdWUgMiI+PGcgaWQ9IkxheWVyXzEiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PGcgaWQ9IllxV2dkeC50aWYiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTI1MCwwaDEzYzExLjQ2LDMuNzcsMTkuNTQsMTIuMTQsMjcuNzQsMjAuMzVxMjguNDQsMjguNDQsNTYuOTEsNTYuODZjMS4xOSwxLjE5LDIuNDQsMi4zMyw0LjM3LDQuMTYsMC02Ljg4LDAtMTIuNTMsMC0xOC4xOEEzMS43NCwzMS43NCwwLDAsMSwzODMuMzMsMzJjMTAuNDktLjA3LDIxLDAsMzEuNDgsMEM0MzQuMzgsMzIsNDQ4LDQ1LjY4LDQ0OCw2NS4zNGMwLDM1LjgsMCw3MS42MS0uMDgsMTA3LjQyYTEwLjQ3LDEwLjQ3LDAsMCwwLDMuNDYsOC4yM2MxMy42NSwxMy40NCwyNy4xMywyNyw0MC43LDQwLjU2LDguMTIsOC4wOSwxNi4xOSwxNi4xOSwxOS45NCwyNy40NXYxNGMtLjY5LDEuNjItMS40NCwzLjIyLTIuMDgsNC44NkEzMS4yLDMxLjIsMCwwLDEsNDgxLDI4OGMtNi4xNi4wNi0xMi4zMywwLTE4LjUsMEg0NDh2Ni42OHEwLDkyLjI1LDAsMTg0LjQ5YzAsMTktMTMuNzksMzIuODEtMzIuNzEsMzIuODJxLTQ1LDAtOTAsMGMtMS42MiwwLTMuMjMtLjEzLTUuMjUtLjIyVjM1NS4wNWMwLTIxLjc2LTEzLjQtMzUuMDYtMzUuMjktMzUuMDdIMjI3LjI0Yy0yMS45LDAtMzUuMjUsMTMuMjktMzUuMjYsMzUuMDhWNTEySDk3LjcyQzc3LjQxLDUxMiw2NCw0OTguNTMsNjQsNDc4LjE2VjI4OGMtMTEuNDEsMC0yMi4yMS4wNi0zMywwQTMxLDMxLDAsMCwxLDEuOTMsMjY3LjRDMS4zNywyNjUuOS42NSwyNjQuNDYsMCwyNjNWMjQ5YzMuMzQtOS4yMyw5LjE5LTE2LjcxLDE2LjA2LTIzLjU3UTEyMC45MywxMjAuNzIsMjI1LjcxLDE1LjkzQzIzMi43Miw4LjkxLDI0MC4yLDIuNzEsMjUwLDBaIi8+PC9nPjwvZz48L2c+PC9zdmc+');
          background-repeat: no-repeat;
          left: $mobile-gutter;
        }

        img {
          height: inherit;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }

    .container-fluid {
      padding-left: 100px;
      @include media-breakpoint-down(md) {
        padding-left: $mobile-gutter;
        padding-top: 49px;
      }
    }

    .view-opigno-my-courses {
      display: flex;
      @include media-breakpoint-down(md) {
        display: block;
      }

      .view-filters {
        flex: 0 1 260px;
        max-width: 360px;
        @include media-breakpoint-down(md) {
          margin-bottom: 2rem;
        }
      }

      .view-content {
        flex: 1;

        .views-row {
          & + .views-row {
            margin-top: 2rem;
          }
        }
      }
    }
  }
}
